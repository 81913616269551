html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 14px;
}

/* workaround for https://github.com/mui-org/material-ui/issues/29819 */
.MuiCardActions-spacing > :not(:first-child) {
  margin-left: 8px;
}

/* hide graphiql theme settings */
.graphiql-dialog-header + .graphiql-dialog-section:not(:last-child) {
  display: none !important;
}

.card-text {
  white-space: normal;
}

.log-line,
.log-line span {
  word-wrap: normal;
  font-family: Monaco, monospace;
  font-size: 12px;
  white-space: pre-wrap;
}

.ansi-black-fg {
  color: black;
}

.ansi-bright-black-fg {
  color: #686a66;
}

.ansi-red-fg {
  color: #d81e00;
}

.ansi-bright-red-fg {
  color: #f54235;
}

.ansi-green-fg {
  color: #5ea702;
}

.ansi-bright-green-fg {
  color: #99e343;
}

.ansi-yellow-fg {
  color: #cfae00;
}

.ansi-bright-yellow-fg {
  color: #fdeb61;
}

.ansi-blue-fg {
  color: #427ab3;
}

.ansi-bright-blue-fg {
  color: #84b0d8;
}

.ansi-magenta-fg {
  color: #89658e;
}

.ansi-bright-magenta-fg {
  color: #bc94b7;
}

.ansi-cyan-fg {
  color: #00a7aa;
}

.ansi-bright-cyan-fg {
  color: #37e6e8;
}

.ansi-white-fg {
  color: #dbded8;
}

.ansi-bright-white-fg {
  color: #f1f1f0;
}

.ansi-black-bg {
  background-color: black;
}

.ansi-red-bg {
  background-color: #d81e00;
}

.ansi-green-bg {
  background-color: #5ea702;
}

.ansi-yellow-bg {
  background-color: #cfae00;
}

.ansi-blue-bg {
  background-color: #427ab3;
}

.ansi-magenta-bg {
  background-color: #89658e;
}

.ansi-cyan-bg {
  background-color: #00a7aa;
}

.ansi-white-bg {
  background-color: #dbded8;
}
